<template>
  <page-layout :ready="!loading">
    <template slot="breadcrumb">
      <el-breadcrumb-item :to="{ name: 'priceListsManagement' }">
        {{ $t('routes.priceListsManagement') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ priceListUpdate && priceListUpdate?.priceList?.name }}</el-breadcrumb-item>
      <el-breadcrumb-item> {{ $t('priceListManagement.priceListUpdate.priceListUpdate') }} </el-breadcrumb-item>
    </template>
    <div v-if="priceListUpdate">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <h3>{{ priceListUpdate?.priceList?.name }}</h3>
          <div class="badge bg-secondary p-2 m-2">
            {{ $t('priceListManagement.priceListUpdate.draft') }}
          </div>
        </div>
        <div>
          <Button type="secondary" :disabled="multipleMode">
            {{ $t('priceListManagement.priceListUpdate.showDifference') }}
          </Button>
          <Button type="primary" :disabled="multipleMode" @click="handleSavePriceListUpdates">
            {{ $t('priceListManagement.priceListUpdate.saveAndUpdate') }}
          </Button>
        </div>
      </div>
      <h3 class="my-4">
        {{ $t('priceListManagement.priceListUpdate.updateDates') }}
        <QuestionMarkIcon />
        <Button type="secondary" :disabled="multipleMode" class="mx-4">
          <PlusIcon /> {{ $t('priceListManagement.priceListUpdate.additionalDate') }}</Button
        >
      </h3>
      <div
        v-loading="updatePriceListUpdateLoading"
        class="card mb-5"
        style="width: 18rem; cursor: pointer"
        @click="editDateMode = true"
      >
        <div class="card-body">
          <div class="card-title fw-bold d-flex align-items-center">
            <span class="badge rounded-pill bg-primary" :class="$direction === 'rtl' ? 'ms-2' : 'me-2'">1</span>
            {{
              $t('priceListManagement.priceListUpdate.startingFrom', {
                date: dateFormatter(priceListUpdate.updateDate),
              })
            }}
          </div>
          <hr />
          <p class="card-text fw-bold">{{ priceListUpdate.name }}</p>
          <p class="card-text">{{ priceListUpdate.description }}</p>
        </div>
      </div>

      <h3 class="mb-4">{{ $t('priceListManagement.priceListUpdate.sections.title') }}</h3>
      <template v-if="multipleMode">
        <div class="alert alert-info fw-bold d-flex align-items-center justify-content-center my-5" role="alert">
          <EditIcon class="mx-1" />
          {{ $t('priceListManagement.priceListUpdate.multipleEditMode') }}
          {{ $t('priceListManagement.priceListUpdate.active') }}.
          <Button type="primary" class="mx-4" @click="submitBatchEdit">
            {{ $t('settings.save') }}
          </Button>
          <Button type="secondary" @click="multipleMode = false">
            {{ $t('commons.cancel') }}
          </Button>
        </div>
        <div v-if="!batchErrorCount" class="d-flex justify-content-center my-5">
          <NoticeIcon width="16" class="mx-1" />
          <div class="border-bottom-dashed">
            {{ $t('priceListManagement.priceListUpdate.magicClick') }}
            {{ $t('priceListManagement.priceListUpdate.active') }}
          </div>
        </div>
        <div v-if="batchErrorCount" class="d-flex justify-content-center my-5 text-danger">
          <WarningIcon width="20" class="mx-1" />
          {{ $tc('priceListManagement.priceListUpdate.incorrectValue', batchErrorCount, { count: batchErrorCount }) }}
        </div>
      </template>

      <div class="row">
        <div class="col-9">
          <Sections :sections.sync="sections" class="mb-4" @change="sectionsPath = $event" />

          <div class="d-flex justify-content-between align-items-center mb-5" :class="multipleMode ? 'text-muted' : ''">
            <div>
              <span v-for="(name, index) in sectionsBreadcrumbs" :key="`section-${name}`">
                <p
                  :dir="$direction"
                  class="d-inline"
                  :class="{
                    ['text-typography-secondary']: index !== sectionsBreadcrumbs.length - 1,
                    ['fw-bold']: index === sectionsBreadcrumbs.length - 1,
                  }"
                >
                  {{ name }}
                  <ChevronIcon
                    v-if="index !== sectionsBreadcrumbs.length - 1"
                    :direction="$direction === 'rtl' ? 'left' : 'right'"
                  />
                </p>
              </span>
            </div>
            <div>
              <NoticeIcon width="20" class="mx-1" :class="!multipleMode ? 'text-primary' : ''" />
              <span :class="$direction === 'ltr' ? 'me-4' : 'ms-4'">
                {{
                  $tc('priceListManagement.priceListUpdate.notListedProducts', notListedProducts, {
                    count: notListedProducts,
                  })
                }}
                {{ $t('priceListManagement.priceListUpdate.viewProducts') }}
              </span>
              <Button type="secondary" :disabled="multipleMode" @click="multipleMode = true">
                <EditIcon /> {{ $t('priceListManagement.priceListUpdate.multipleEditMode') }}</Button
              >
            </div>
          </div>
          <div>
            <PriceListUpdateTableBatchEdit
              v-if="multipleMode"
              ref="batchEditTable"
              :update-date="new Date(priceListUpdate.updateDate)"
              :products="products"
              :prices="priceListUpdate.priceList.prices"
              :business-id="priceListUpdate.businessId"
              @productRefetch="productRefetch"
              @formSubmitted="handleBatchUpdate"
            />
            <PriceTable
              v-else
              v-loading="updatePriceListUpdateLoading"
              :price-list-update="priceListUpdate"
              :products="products"
              :section-products-ids="selectedSectionProductIds"
              @price-update="handlePriceUpdate"
              @price-reset="handleResetUpdate"
            />
          </div>
        </div>
        <div class="col-3">
          <template v-if="priceListUpdate.filePathUrl">
            <OcrDocument
              v-if="ocrDocument"
              :doc-src="priceListUpdate.filePathUrl"
              :ocr-doc="ocrDocument"
              @blocksSelection="pasteToForm"
            />
            <PdfViewer v-else :src="priceListUpdate.filePathUrl" />
            <Button
              v-loading="ocrLoading"
              type="secondary"
              :disabled="priceListUpdate.ocr.google.status === OCR_STATUS.PROCESSED"
              class="float-right mt-1"
              @click="handleRequestOcr"
              >OCR
            </Button>
          </template>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <Button type="secondary" :disabled="multipleMode">
          {{ $t('priceListManagement.priceListUpdate.showDifference') }}
        </Button>
        <Button type="primary" :disabled="multipleMode" @click="handleSavePriceListUpdates">
          {{ $t('priceListManagement.priceListUpdate.saveAndUpdate') }}
        </Button>
      </div>
      <EditDate
        v-if="editDateMode"
        :edit-date-object="editDateObject"
        @closed="editDateMode = false"
        @submit="handleEditDate"
      />
    </div>
  </page-layout>
</template>

<script type="text/javascript">
import { without, equals, clone, union, prop } from 'ramda';
import { computed, ref, watch, getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { Document } from '@clarityo/ocr-reader';

import { options } from '@/locale/dateConfig';
import { PlusIcon, QuestionMarkIcon, NoticeIcon, EditIcon, WarningIcon, ChevronIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { PdfViewer } from '@/modules/core/components/PdfViewer';
import { OcrDocument } from '@/modules/document/components';
import { usePriceListProducts } from '@/modules/price/compositions';

import {
  usePriceListUpdate,
  UPDATE_PRICE_LIST_UPDATE_MUTATION,
  RECORD_PRICE_LIST_UPDATE_MUTATION,
  REQUEST_OCR_PRICE_LIST_UPDATE_MUTATION,
} from './compositions';
import { EditDate, PriceListUpdateTableBatchEdit, PriceTable, Sections } from './components';
import { getSectionsByLevel } from './tools/sectionTree';

const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
const omitTypenameAndNull = (key, value) => (key === '__typename' || value === null ? undefined : value);

const getProductIdsWithNoSection = (productIds = [], sectionsTrees = []) => {
  let allSectionsProductIds = [];
  const extractIds = (sections) =>
    sections?.forEach((section) => {
      if (section.productIds) allSectionsProductIds = allSectionsProductIds.concat(section.productIds);
      extractIds(section.sections);
    });
  extractIds(sectionsTrees);
  return without(allSectionsProductIds, productIds);
};

const removeProductIdsFromSections = (productIdsToRemove, sectionsTrees) => {
  const remove = (sections) =>
    sections?.map((section) => ({
      ...section,
      productIds: section.productIds ? without(productIdsToRemove, section.productIds) : null,
      sections: remove(section.sections),
    }));

  return remove(sectionsTrees);
};

export const editSectionIds = (currentPath, sectionsTrees, idsChanged) => {
  const sectionLevel = currentPath.length - 1;
  const sectionIndex = currentPath[sectionLevel];
  const newSections = removeProductIdsFromSections(idsChanged, sectionsTrees);

  if (!currentPath.length) return newSections;
  const subSections = getSectionsByLevel(currentPath, newSections, sectionLevel);
  subSections[sectionIndex].productIds = idsChanged;
  return newSections;
};

const OCR_STATUS = {
  UNPROCESSED: 'unProcessed',
  IN_PROGRESS: 'inProgress',
  FAILED: 'failed',
  PROCESSED: 'processed',
};

export default {
  components: {
    PlusIcon,
    QuestionMarkIcon,
    ChevronIcon,
    Button,
    NoticeIcon,
    EditIcon,
    EditDate,
    PriceListUpdateTableBatchEdit,
    WarningIcon,
    PriceTable,
    Sections,
    PdfViewer,
    OcrDocument,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const priceListUpdateId = ref(root.$route.params.id);
    const {
      priceListUpdate,
      loading: priceListUpdateLoading,
      refetch: refetchPriceListUpdate,
    } = usePriceListUpdate(priceListUpdateId.value);
    const {
      products,
      refetch: productRefetch,
      loading: productsLoading,
    } = usePriceListProducts(computed(() => priceListUpdate.value?.businessId));
    const editDateObject = computed(() => ({
      name: priceListUpdate.value?.name,
      description: priceListUpdate.value?.description,
      updateDate: priceListUpdate.value?.updateDate,
    }));

    const { mutate: updatePriceListUpdate, loading: updatePriceListUpdateLoading } = useMutation(
      UPDATE_PRICE_LIST_UPDATE_MUTATION
    );

    const ocrLoading = ref(false);
    let intervalId = null;
    watch(
      ocrLoading,
      (value) => {
        if (value) {
          if (!intervalId) intervalId = setInterval(refetchPriceListUpdate, 3000);
        } else {
          if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
        }
      },
      { immediate: true }
    );
    const { mutate: requestOcr, onError } = useMutation(REQUEST_OCR_PRICE_LIST_UPDATE_MUTATION);
    onError(() => root.$message.error(root.$t('errors.action')));

    const {
      mutate: recordPriceListUpdate,
      onDone: recordPriceListUpdateOnDone,
      onError: recordPriceListUpdateOnError,
    } = useMutation(RECORD_PRICE_LIST_UPDATE_MUTATION);

    recordPriceListUpdateOnError((error) => {
      const errorDetails = error.graphQLErrors[0]?.extensions.response.body.details;
      if (errorDetails?.type === 'priceDateConflict') {
        const rowIndexes = [];
        errorDetails.priceIds.map((priceId) => {
          return priceListUpdate.value.priceList.prices.forEach((price, index) => {
            if (price.id === priceId) {
              rowIndexes.push(index + 1);
            }
          });
        });
        root.$alert(
          root.$i18n.tc('priceListManagement.priceListUpdate.recordErrorText', rowIndexes, {
            count: rowIndexes,
            joinArrays: ', ',
          }),
          root.$i18n.t('priceListManagement.priceListUpdate.recordErrorTitle'),
          { type: 'error' }
        );
      } else {
        root.$message.error(root.$i18n.t('errors.action'));
      }
    });
    recordPriceListUpdateOnDone(() => {
      root.$router.push({
        name: 'priceListsManagement',
      });
    });

    const sectionsPath = ref([]);

    return {
      priceListUpdate,
      products,
      selectedSectionProductIds: computed(() => {
        if (!sectionsPath.value.length) {
          const pricesProductIds = union(
            (priceListUpdate.value?.priceList?.prices ?? []).map(prop('productId')),
            (priceListUpdate.value?.changes ?? []).map(prop('productId'))
          );
          return getProductIdsWithNoSection(pricesProductIds, priceListUpdate.value?.sections);
        }
        const sectionLevel = sectionsPath.value.length - 1;
        const sectionIndex = sectionsPath.value[sectionLevel];
        const sections = getSectionsByLevel(sectionsPath.value, priceListUpdate.value?.sections, sectionLevel);
        return sections[sectionIndex]?.productIds ?? [];
      }),
      loading: computed(() => priceListUpdateLoading.value || productsLoading.value),
      multipleMode: ref(false),
      editDateMode: ref(false),
      editDateObject,
      batchErrorCount: ref(0),
      updatePriceListUpdate,
      updatePriceListUpdateLoading,
      recordPriceListUpdate,
      requestOcr,
      refetchPriceListUpdate,
      sections: ref(priceListUpdate.value?.sections ?? []),
      sectionsPath,
      sectionsBreadcrumbs: [root.$t('priceListManagement.priceListUpdate.sections.noSection')],
      OCR_STATUS,
      ocrDocument: ref(null),
      ocrLoading,
      productRefetch,
    };
  },
  computed: {
    notListedProducts() {
      return this.products.length ? this.products.length - this.priceListUpdate.priceList.prices.length : 0;
    },
  },
  watch: {
    priceListUpdate() {
      this.sections = clone(this.priceListUpdate.sections ?? []);
    },
    multipleMode() {
      this.batchErrorCount = 0;
    },
    sectionsPath() {
      if (!this.sectionsPath.length)
        this.sectionsBreadcrumbs = [this.$t('priceListManagement.priceListUpdate.sections.noSection')];
      else {
        const names = [];
        let subSection = { sections: this.sections };
        for (const pathIndex of this.sectionsPath) {
          subSection = subSection.sections[pathIndex];
          names.push(subSection.name);
        }
        this.sectionsBreadcrumbs = names;
      }
    },
    sections(sections) {
      if (!equals(sections, this.priceListUpdate.sections)) {
        this.updateChanges({ Sections });
      }
    },
    'priceListUpdate.ocr.google.status': {
      handler(status) {
        this.ocrLoading = status === OCR_STATUS.IN_PROGRESS;
      },
      immediate: true,
    },
    async 'priceListUpdate.ocr.google.generalPathUrl'(url) {
      if (!url) return;
      const updateId = this.priceListUpdateId;
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error();
        const text = await response.text();
        if (updateId !== this.priceListUpdateId) return;
        this.ocrDocument = Document.parseGoogleOCRData(text);
      } catch (err) {
        console.error(err);
        this.$message.error(this.$t('errors.action'));
      }
    },
  },
  methods: {
    dateFormatter(ms) {
      return new Date(ms).toLocaleDateString(this.$i18n.locale, options.short);
    },
    submitBatchEdit() {
      this.$refs.batchEditTable.submitForm();
    },
    handleEditDate(updatedEditDateObject) {
      this.editDateMode = false;
      this.updatePriceListUpdate({
        id: this.priceListUpdate.id,
        updateParams: {
          ...updatedEditDateObject,
          priceListId: this.priceListUpdate.priceListId,
          changes: JSON.parse(JSON.stringify(this.priceListUpdate.changes), omitTypename),
          sections: JSON.parse(JSON.stringify(this.sections), omitTypenameAndNull),
        },
      });
    },
    async handleBatchUpdate({ data, errorCount }) {
      this.batchErrorCount = errorCount;
      if (this.batchErrorCount) return;
      this.multipleMode = false;
      const productIds = data.map(({ productId }) => productId);

      const changes = this.priceListUpdate.changes
        ? this.priceListUpdate.changes.filter((change) => !productIds.includes(change.productId))
        : [];
      changes.push(
        ...data.filter(({ productId, price }) => {
          const productPrice = this.priceListUpdate.priceList.prices.find((price) => productId === price.productId);
          const currentPrice = productPrice?.data.reduce((last, priceInfo) =>
            priceInfo.date > last.date ? priceInfo : last
          )?.price;
          return price !== currentPrice;
        })
      );

      const removedPrices = this.priceListUpdate.priceList.prices
        .filter((price) => data.find(({ productId }) => productId !== price.productId))
        .filter((price) => this.sections.find((section) => section.productId !== price.productId))
        .map(({ productId }) => ({ productId, price: null }));

      changes.push(...removedPrices);

      const sections = editSectionIds(this.sectionsPath, this.sections, productIds);

      this.updateChanges({ changes, sections });
    },
    handlePriceUpdate({ productId, price }) {
      const changes = this.priceListUpdate.changes
        ? this.priceListUpdate.changes.filter((change) => change.productId !== productId)
        : [];
      changes.push({ productId, price });
      this.updateChanges({ changes });
    },
    handleResetUpdate(productId) {
      this.updateChanges({
        changes: this.priceListUpdate.changes.filter((change) => change.productId !== productId),
      });
    },
    updateChanges({ changes, sections }) {
      this.updatePriceListUpdate({
        id: this.priceListUpdate.id,
        updateParams: {
          priceListId: this.priceListUpdate.priceListId,
          name: this.priceListUpdate.name,
          description: this.priceListUpdate.description,
          updateDate: this.priceListUpdate.updateDate,
          changes: JSON.parse(JSON.stringify(changes ?? this.priceListUpdate.changes), omitTypename),
          sections: JSON.parse(JSON.stringify(sections ?? this.sections), omitTypenameAndNull),
        },
      });
    },
    handleSavePriceListUpdates() {
      if (!this.priceListUpdate.name || !this.priceListUpdate.updateDate) {
        this.$alert('Please add price list update name and update date', { type: 'warning' });
        return;
      }
      this.recordPriceListUpdate({ id: this.priceListUpdate.id }).catch();
    },
    handleRequestOcr() {
      this.requestOcr({ id: this.priceListUpdate.id });
      this.ocrLoading = true;
    },
    async pasteToForm(textBlocks) {
      const data = textBlocks.map(({ text }) => text);
      this.$refs.batchEditTable.pasteToFocusedField(data);
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.border-bottom-dashed {
  border-bottom: 1px $typography-primary dashed;
}
</style>
