import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function usePriceListProducts(businessId) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(
    PRODUCTS_QUERY_NEW,
    () => ({ businessId: businessId.value }),
    () => ({
      enabled: !!businessId?.value,
    })
  );
  const products = computed(() => (result.value ? result.value.productsNew3?.nodes ?? [] : []));

  onError((err) => {
    console.error('usePriceListProducts', err);
    error();
  });

  return {
    products,
    refetch,
    loading,
  };
}

const PRODUCTS_QUERY_NEW = gql`
  query priceListProducts($businessId: ID) {
    productsNew3(businessId: $businessId) {
      nodes {
        id
        name
        sku
        gtin
        business {
          name
        }
        bom {
          quantity
          uom
          orderUnit
          billingUnit
          bom {
            quantity
            uom
            orderUnit
            billingUnit
            bom {
              quantity
              uom
              orderUnit
              billingUnit
            }
          }
        }
        associatedProducts {
          productId
          quantity
        }
        references {
          name
          sku
        }
      }
    }
  }
`;
